<template>
  <div>
    <div>
      <div class="mainTitle">实验教学资源管理</div>
      <div class="content">
        实验教学资源管理是一种面向教育行业用户群体的管理系统。电子教材视频、图片等作为纸本教材的延伸，具备低成本、易管理、便保存等特点。丰富师生课余时间，随时随地翻阅并且支持在线收藏功能，学生可查个人课表的教材，老师和教务处可查全校师生的课表教材。
      </div>
      <img src="@/assets/productcore/shiyanshi1.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">实践教学安排管理</div>
      <div class="content">
        实践教学安排管理通过设定基础数据、教师安排、排课规则和约束条件从而近进行智能排课。智能排课管理最大限度减少人工排课的工作量，同时实现对教学场地和教师资源的合理安排，最大限度利用教学资源。快速响应和满足教师部门提出的课表修改或新增需求。学生
        可查个人课表，老师和教务处可查全体师生的课表，支持手动个性化排课。
      </div>
      <img src="@/assets/productcore/shiyanshi2.png" alt="" />
    </div>
    <div>
      <div class="mainTitle">考试平台</div>
      <div class="content">
        考试平台实现了智能化从海量题库，精确抽取试题，组成试卷等应用于一体的智慧试卷管理平台支持单/多选、判断填空、材料、综合题等多种题型;快速批量导入试卷，批量编辑试题，自动组卷，节约题库管理时间;支持智能阅卷，实时查看分数及试题解析等智能化题库管理平台试卷管理、题库管理、在线教育、预约考试、考场管理等应用，支持流程定义、场
景适配、数据分析等个性化服务。
      </div>
      <img src="@/assets/productcore/shiyanshi3.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "laboratory-manage",
};
</script>

<style scoped>
.mainTitle {
  font-size: 30px;
  color: #333;
  margin: 56px 0 40px;
  position: relative;
}
.mainTitle::after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #d7d7d7;
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
}
.content {
  color: #333;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 32px;
  letter-spacing: 0.2px;
}
img {
  width: 86%;
}
</style>